<template>
    <div class="container h-100 my-2">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-12 col-md-8 col-lg-6 col-xl-5">
                <div class="box">
                <h2>{{ $t("compte.creation_compte") }}</h2>
                    <form @submit.prevent="validForm">

                        <!-- Nom -->
                        <div class="form-group">
                            <label for="name">{{ $t('compte.nom') }}<sup>*</sup></label>

                            <div class="input-group">
                                <input autocomplete="off" class="form-control" type="text" :placeholder="$t('compte.nom')" v-model="name" id="name" required>
                            </div>
                        </div>

                        <!-- Prenom -->
                        <div class="form-group">
                            <label for="firstname">{{ $t('compte.prenom') }}<sup>*</sup></label>

                            <div class="input-group">
                                <input autocomplete="off" class="form-control" type="text" :placeholder="$t('compte.prenom')" v-model="firstname" id="firstname" required>
                            </div>
                        </div>

                        <!-- Email -->
                        <div class="form-group">
                            <label for="mail">{{ $t('compte.email') }}<sup>*</sup></label>

                            <div class="input-group" v-if="this.email">
                                <input autocomplete="off" class="form-control" type="mail" :placeholder="$t('compte.email')" v-model="mail" id="mail" required readonly>
                            </div>
							<div class="input-group" v-else>
                                <input autocomplete="off" class="form-control" type="mail" :placeholder="$t('compte.email')" v-model="mail" id="mail" required>
                            </div>
                        </div>

                        <!-- Mot de passe -->
                        <div class="form-group">
                            <label for="password">{{ $t('compte.password') }}<sup>*</sup></label>

                            <div class="input-group">
                                <input autocomplete="off" class="form-control" type="password" :placeholder="$t('compte.password')" v-model="password" id="password" required>
                            </div>
                        </div>

                        <!-- Mot de passe confirmation -->
                        <div class="form-group">
                            <label for="password_confirmation">{{ $t('compte.password_confirmation') }}<sup>*</sup></label>

                            <div class="input-group">
                                <input autocomplete="off" class="form-control" type="password" :placeholder="$t('compte.password_confirmation')" v-model="password_confirmation" id="password_confirmation" required>
                            </div>
                        </div>

                        <!-- Téléphone -->
                        <div class="form-group">
                            <label for="telephone">{{ $t('compte.telephone') }}</label>

                                <div class="row no-gutters">
                                    <div class="col-auto pr-2 phone">
                                        <phoneInput v-model="phone"></phoneInput>
                                    </div>
                                    <div class="col pr-2">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <div class="input-group-text">{{ phone.phone_indicatif }}</div>
                                            </div>
                                            <input type="text" class="form-control" v-model="phone.phone_numero">
                                        </div>
                                    </div>
                                </div>
                        </div>

                        <!-- Message d'erreur si besoin -->
                        <ErrorAlert v-if="message_erreur_code !== ''" :messageI18n="message_erreur_code" />

                        <!-- Bouton de validation du formulaire -->
                        <button type="submit" v-on:click="checkForm" class="mt-4 btn btn-primary btn-block rounded-pill">
                            {{ $t('global.ajouter') }} <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
    import Vue from 'vue'
    import Config from "@/mixins/Config.js";
    import User from "@/mixins/User.js";
    import Tools from "@/mixins/Tools.js";
    import Navigation from "@/mixins/Navigation.js";

    import Countries from '@/assets/lang/countries/countries'
    import Indicatif from '@/assets/lang/countries/indicatif'

    export default {
        name: "CompteCreation",
        mixins: [Config, User, Tools, Navigation],
        props: {
			cp: String,
			redirect: String,
			email: String,
			aqs: String  // Auth Query Signature - token autorisant la récup des infos user à partir de l'email
        },
        data () {
            return {
                name: null,
                firstname: null,
                mail: null,
                password: null,
                password_confirmation: null,
                ad1: null,
                ad2: null,
                code_postal: null,
                ville: null,
                datenaissance: null,
                indicatifs: {},
                countries: {},
                countries_iso: {},
                phone: {
                    phone_indicatif: '+33',
                    phone_numero: '',
                    phone_country: "FRA"
                },
                message_erreur_code: "",
                processing: false
            }
		},
		computed: {
			hasAutoComplete() {
				return Boolean(this.email) && Boolean(this.aqs)
			}
		},
		created() {
			if(this.redirect) {
				this.verifyRedirectDomain(this.redirect)
				.catch(e => {
					console.error(e)
					this.$router.push({name: 'Home'})
				})
			}
		},
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                const actual_date = new Date()
                this.datenaissance = actual_date.toDateInputValue()

                this.indicatifs = Indicatif
                this.countries = Countries[Vue.i18n.locale()].label
                this.countries_iso = Countries[Vue.i18n.locale()].iso_3

				this.mail = this.email
				this.autoCompleteForm()
            },

			async autoCompleteForm() {
				// On va chercher les infos du user pour autocomplete le form
				if(this.hasAutoComplete) {
					const res = await this.autocompleteCaTierRegister(this.email, this.aqs)

					if(!res.tiers_lastname && ! res.tiers_firstname) {
						this.name = res.tiers_rs
					}
					else {
						this.name = res.tiers_lastname
						this.firstname = res.tiers_firstname
					}

					if(res.phones.length > 0) {
						const phone = res.phones[0]
						this.phone.phone_indicatif = phone.phone_indicatif
						this.phone.phone_numero    = phone.phone_numero

						// Avec la migration des codes iso, des numéros sont enregistrés avec l'ancien code iso
						// On va récupérer le code iso à partir de l'indicatif
						Object.keys(this.countries_iso).forEach(key => {
							if(this.countries_iso[key] == phone.phone_indicatif) {
								this.phone.phone_country = key
							}
						})
					}
				}
			},

            async validForm() {
                this.processing = true
                let user = {
                    name: this.name,
                    firstname: this.firstname,
                    mail: this.mail,
                    password: this.password,
                    password_confirmation: this.password_confirmation,
                    code_parrain: '',
                    indicatif: this.phone.phone_indicatif,
                    phone: this.phone.phone_numero,
					// Code ISO à 3 lettre ne fonctionne pas pour les numéros de téléphone, merci à tous
                    country: this.phone.phone_country.slice(0, 2)
                }

                let res = await this.createUser(user, '', true)
                if(res.code_retour) {
					this.successToast('toast.account_created')

                    // this.$cache.user_id = res.retour.id
                    await this.setConfig("user_id", res.retour.id)
                    
					await this.loginUser(this.mail, this.password, '')
                }
                else {
                    this.message_erreur_code = res.retour
                    if(this.message_erreur_code == 'La valeur du champ adresse email est déjà utilisée. ') {
                        await this.loginUser(this.mail, this.password, '', true)
                    }
                }
                this.processing = false
            },
            indicatif(country) {
                this.phone.phone_indicatif = this.indicatifs[country]
                this.phone.phone_country = country
            }
        },
        components: {
            HeaderTab: () => import('@/components/HeaderTab'),
            ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert'),
            PhoneInput : () => import('GroomyRoot/components/Inputs/PhoneInput'),
        }
    };
</script>
